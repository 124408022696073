import React from "react";
import Fade from "react-reveal/Fade";

import careers1 from "../../images/careers1.webp";
import careers2 from "../../images/careers2.webp";

const CareersHeader = () => {
  return (
    <div className="max-w-7xl mx-auto mt-10 px-4">
      <Fade bottom cascade>
        <div className="flex flex-col lg:flex-row">
          {/* 文本部分 */}
          <div className="lg:w-2/4 w-full m-2 text-white bg-lightblack p-8 rounded-xl">
            <h2 className="text-sm opacity-50 font-semibold">Careers</h2>
            <h1 className="text-3xl lg:text-5xl font-bold">
              Join us, and help everyone <br /> create for the web.
            </h1>
            <p className="mt-5 opacity-70">
              At Orangethink, we’re not just building platforms; we’re shaping the future of the web. 
              From cross-border e-commerce to global novel platforms, our technology empowers creators to unleash their creativity with ease.
              <br /><br />
              No matter how technology evolves, our mission remains the same: 
              to provide everyone with simple yet powerful tools to bring their creative visions to life.
            </p>
          </div>
          {/* 图片部分 */}
          <div className="lg:w-2/4 w-full rounded-xl overflow-hidden m-2">
            <img src={careers1} className="w-full h-auto" alt="Career Image 1" />
          </div>
        </div>
      </Fade>

      <Fade bottom cascade>
        <div className="flex flex-col-reverse lg:flex-row-reverse mt-10">
          {/* 文本部分 */}
          <div className="lg:w-2/5 w-full m-2 text-white bg-lightblack p-8 rounded-xl">
            <h2 className="text-sm opacity-50 font-semibold">Careers</h2>
            <h1 className="text-3xl lg:text-5xl font-bold">
              Join us, and help everyone <br /> create for the web.
            </h1>
            <p className="mt-5 opacity-70">
              At Orangethink, we focus on more than just providing technology—we’re building a platform where creativity thrives. 
              From startup projects to large-scale global web applications, we offer innovative tools that allow everyone to create their own impact on the web with ease.
              <br /><br />
              No matter how technology changes, our goal is to empower every creator to realize their dreams in the digital age.
            </p>
          </div>
          {/* 图片部分 */}
          <div className="lg:w-3/5 w-full rounded-xl overflow-hidden m-2">
            <img src={careers2} className="w-full h-auto" alt="Career Image 2" />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default CareersHeader;
