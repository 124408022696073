import React from "react"
import Fade from "react-reveal/Fade"

const CareersRoles = () => {
  return (
    <div className="max-w-7xl mx-auto flex flex-col items-center text-white mt-20 font-poppins">
      <h2 className="text-sm font-bold opacity-50">Join our team</h2>
      <h1 className="text-6xl font-bold mt-3 text-center">View open roles</h1>
      <Fade bottom cascade>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10 w-full">
          <a
            href="#"
            className="m-5 p-5 bg-lightblack rounded-xl border cursor-pointer hover:border-purple text-left"
          >
            <h2 className="text-3xl font-bold">Product Design Manager</h2>
            <p className="mt-10 opacity-50">
              As a Product Design Manager at Orangethink, you will lead a team to create products that excel in user experience and technological innovation. From conceptualization to product launch, your designs will drive progress on our cross-border e-commerce platform and global novel platform. We are looking for a leader with a sharp design vision and a deep understanding of users, capable of delivering the best experiences to a global audience in a rapidly changing digital landscape.
            </p>
          </a>
          <a
            href="#"
            className="m-5 p-5 bg-lightblack rounded-xl border cursor-pointer hover:border-purple text-left"
          >
            <h2 className="text-3xl font-bold">Senior Data Engineer</h2>
            <p className="mt-10 opacity-50">
              As a Senior Data Engineer at Orangethink, you will design, build, and optimize scalable data architectures that are critical to our cross-border e-commerce and global novel platforms. You’ll collaborate with various teams to drive business decisions with data-driven insights, ensuring the efficiency and security of data processes. We are looking for an experienced data engineer who thrives in large-scale data environments and has a passion for the latest in data technology.
            </p>
          </a>
        </div>
      </Fade>
    </div>
  )
}

export default CareersRoles
